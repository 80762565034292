import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useStyles } from './styles';
import { Box, Typography, Tooltip } from '@cumplo/design-system';

const Score = ({ text }) => {
 const classes = useStyles();

 return (
  <Box display="flex" justifyContent="center"
   paddingX= "4px"
   paddingY= "16px"
   alignItems="center"
   width="100%"
  >
   <Typography
    variant="body2"
   >
    {text[1]} <Typography variant="body2"  component="span" fontWeight={600}>{` ${text[0]}`}</Typography>
    {` de ${[1].toLocaleString(
     process.env.GATSBY_REACT_APP_REGION,
     { minimumFractionDigits: 1 }
    )}`}
   </Typography>
  
   {text[1] === 'Calificación' && (
    <Tooltip title={(
     <div>
      <Box>
      Puntaje del 0 al 1 que refleja el riesgo de la oportunidad de inversión. A mejor puntaje, la oportunidad es menos riesgosa.
      El puntaje se crea con un algoritmo que mide el comportamiento de la empresa pagadora en relación al solicitante y la industria de la empresa solicitante.       </Box>
     </div>
    )}
    enterTouchDelay={0}
    leaveTouchDelay={3000}
    >
     <InfoIcon className={classes.infoTooltip} />
    </Tooltip>
   )}
  </Box>
 )}

export default Score;